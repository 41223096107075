import React, { useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { useBoolean } from 'usehooks-ts';

import { GoldEvents } from '@/modules/analytics/event-types';
import useRudderTrack from '@/modules/analytics/hooks/useRudderTrack';
import Price from '@/tayara-kit/Price';
import Text from '@/tayara-kit/Text';

import { IListingItem } from '../../types';
import { genItemLink } from '../../utils/utils';

interface IGoldenClassified extends IListingItem {
  message: string;
  actionText: string;
  actionLink: string;
  announcerLogo: string;
  announcerName: string;
}

// TODO
// Add tracking to golden classified
// Track call clicks
// Track profile clicks
// Track classified visit click

const GoldenClassified: React.FC<{
  goldenClassified: IListingItem | null;
}> = ({ goldenClassified }) => {
  const [completeGoldenClassified, setCompleteGoldenClassified] =
    useState<IGoldenClassified | null>(goldenClassified as IGoldenClassified);
  useEffect(() => {
    // console.log('goldenClassified', goldenClassified);
    if (goldenClassified !== undefined && goldenClassified !== null) {
      setCompleteGoldenClassified({
        ...goldenClassified,
        actionText: 'Appeler',
        actionLink: `tel:${goldenClassified.phone}`,
        announcerLogo: goldenClassified.metadata?.publisher?.avatar,
        announcerName: goldenClassified.metadata?.publisher?.name,
        message: `Démarquez vous des autres, choisissez nous`,
      } as IGoldenClassified);
    } else {
      setCompleteGoldenClassified({
        announcerName: 'Espace Golden',
        announcerLogo: '/media/t-logo-primary.png',
        actionText: 'Réserver',
        actionLink: 'https://www.tayara.tn/leads/tayara-pro/',
        title: 'Votre annonce en premier plan',
        message: "Recevez plus d'appels maintenant",
        images: ['/media/emptyGoldenClassifiedImg.webp'],
      } as IGoldenClassified);
    }
  }, [goldenClassified]);

  const { value: phoneShown, setTrue: setPhoneShownTrue } = useBoolean(false);
  const { rudderTrack } = useRudderTrack();
  // console.log(category);
  const compose =
    (...args: Function[]) =>
    () => {
      args.forEach((_, i) => args[i]?.());
    };

  const imgSrc = completeGoldenClassified?.images[0] || '';
  const announcerLogo = completeGoldenClassified?.announcerLogo || '';

  return (
    <div className="w-full h-[265px] md:h-[452px] bg-yellow-75 rounded-xl overflow-hidden relative flex">
      {/* Picture layer */}

      <Image
        src={
          imgSrc.includes('http')
            ? `https://www.tayara.tn/mediaGateway/resize-image?img=${imgSrc.substring(
                imgSrc.lastIndexOf('/') - 2
              )}&w=500`
            : `${imgSrc}`
        }
        unoptimized
        loading="lazy"
        className={`bg-neutral-300 w-full object-cover`}
        fill
        alt={`${completeGoldenClassified?.title}`}
      />
      {/* Card click layer */}
      <Link
        href={
          completeGoldenClassified?.id
            ? genItemLink(completeGoldenClassified)
            : 'https://www.tayara.tn/leads/tayara-pro/'
        }
        passHref
        target="_blank"
        onClick={() => rudderTrack(GoldEvents.CLICK_CLASSIFIED, null)}
      >
        <div className="absolute inset-0 z-[3]" />
      </Link>

      {/* Text layer */}
      <div className="absolute left-0 right-0 top-0 px-4 pb-20  pt-5 text-white">
        <div className="absolute inset-0 z-[1] bg-gradient-to-b from-black to-black/0 mix-blend-overlay"></div>
        <div className="absolute inset-0 z-[1] bg-gradient-to-b from-black/40 to-black/0"></div>
        <div className="relative flex flex-col z-[2]">
          <h2 className="font-extrabold text-xl text-center md:text-3xl leading-6 md:leading-8 uppercase tracking-wide text-white">
            {completeGoldenClassified?.title}
          </h2>

          <Price
            price={completeGoldenClassified?.price || 0}
            className="text-white text-3xl text-center font-light mt-2"
            unitComponent={
              <sup>
                <Text
                  className="text-sm font-medium bg-white text-black rounded-md px-0.5"
                  text="DT"
                />
              </sup>
            }
          />
        </div>
      </div>

      <div className="absolute bottom-0 mb-3 left-0 right-0 px-3 flex flex-col space-y-3 z-[4]">
        <div className="bg-gradient-to-br p-3 from-amber-200 to-yellow-500 flex justify-between w-full rounded-2xl h-[90px] gap-x-3">
          {/* Boutique logo */}
          <Link
            href={
              completeGoldenClassified?.metadata?.publisher?.id
                ? `/shop/${completeGoldenClassified?.metadata?.publisher?.id}`
                : 'https://www.tayara.tn/leads/tayara-pro/'
            }
            passHref
            onClick={() => rudderTrack(GoldEvents.CLICK_AVATAR, null)}
            className="h-full aspect-square ring ring-black rounded-md overflow-hidden"
          >
            <img
              loading="lazy"
              src={
                announcerLogo.includes('http')
                  ? `https://www.tayara.tn/mediaGateway/resize-image?img=${announcerLogo.substring(
                      announcerLogo.lastIndexOf('/') - 2
                    )}&w=100`
                  : `/media/t-logo-primary.png`
              }
              alt={`Publisher logo of ${completeGoldenClassified?.metadata?.publisher?.name} on tayara `}
              className="h-full w-full object-cover"
            />
          </Link>
          <div className="flex flex-col justify-center">
            <Link
              href={
                completeGoldenClassified?.metadata?.publisher?.id
                  ? `/shop/${completeGoldenClassified?.metadata?.publisher?.id}`
                  : 'https://www.tayara.tn/leads/tayara-pro/'
              }
              passHref
              onClick={() => rudderTrack(GoldEvents.CLICK_NAME, null)}
            >
              <h3 className="text-black font-extrabold text-lg leading-7 line-clamp-1 capitalize">
                {completeGoldenClassified?.announcerName?.toLowerCase()}
              </h3>
            </Link>
            <p className="text-black leading-4 text-xs max-w-[120px]">
              {phoneShown ? (
                <p>
                  Appel en cours pour{' '}
                  <span className="font-bold tracking-wide">
                    {completeGoldenClassified?.phone}
                  </span>
                </p>
              ) : (
                completeGoldenClassified?.message
              )}
            </p>
          </div>

          {/* Action link */}
          <Link
            href={`${completeGoldenClassified?.actionLink}`}
            passHref
            className="bg-primary text-xs text-white py-1 px-3 font-bold rounded-3xl mx-0 my-auto"
            onClick={compose(setPhoneShownTrue, () =>
              rudderTrack(GoldEvents.CLICK_PHONE, null)
            )}
          >
            {completeGoldenClassified?.actionText}
          </Link>
        </div>
        {false && (
          <ul className="bg-neutral-100 p-1 rounded-full flex gap-x-1 mt-auto mx-auto ">
            {completeGoldenClassified?.images.map((_, index) => (
              <li
                key={index}
                className="w-1.5 h-1.5 rounded-full bg-neutral-700"
              ></li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default GoldenClassified;
