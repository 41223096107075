/* eslint-disable no-nested-ternary */

import React, { FunctionComponent, useRef } from 'react';

import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import { FaShapes } from 'react-icons/fa';
import { HiLocationMarker } from 'react-icons/hi';
import { MdOutlineStorefront } from 'react-icons/md';

import { APP_CONFIG } from '@/app-config';
import { getSubCategoryById } from '@/lib/helpers';
import useAgeVerification from '@/modules/auth/hooks/VerifiyAgeHook';
import AgeVerificationModal from '@/modules/core/components/AgeVerificationModal';
import useBreakpoints from '@/modules/core/hooks/useBreakpoints';
import { IListingItem } from '@/modules/core/types';
import { genItemLink } from '@/modules/core/utils/utils';
import Price, { PriceType } from '@/tayara-kit/Price';
import Text from '@/tayara-kit/Text';

export enum ClassifiedCardLayout {
  Flexible,
  Fixed,
  Wide,
}

interface ClassifiedCardProps extends React.HTMLAttributes<HTMLDivElement> {
  isMyListing?: boolean;
  classifiedCardData: IListingItem;
  layout?: ClassifiedCardLayout;
}

const ClassifiedCard: FunctionComponent<ClassifiedCardProps> = ({
  classifiedCardData,
  className,
  ...props
}) => {
  const standardCardWidth = useBreakpoints(APP_CONFIG.standardWidth);
  const standardCardHeight = useBreakpoints(APP_CONFIG.standardHeight);

  const containerRef = useRef<HTMLDivElement>(null);
  const metadataClassName = `text-2xs font-light text-neutral-800`;
  const { title, description, price, metadata, images } = classifiedCardData;
  const name = metadata?.publisher?.name;
  const isShop = metadata?.publisher?.isShop;
  const avatar = metadata?.publisher?.avatar;
  const subCategory = getSubCategoryById(metadata?.subCategory)?.name;
  const imgSrc = images?.[0] || '';
  const isNeuf = subCategory === 'Immobilier Neuf';
  const NeedToVerifyAge = metadata?.publisher?.name === 'IQOS';
  const { isVerified, verifyAge, showModal, handleConfirm, handleCloseModal } =
    useAgeVerification();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (NeedToVerifyAge && !isVerified) {
      e.preventDefault();
      verifyAge();
    }
  };

  return (
    <article {...props}>
      <AgeVerificationModal
        isOpen={showModal}
        onClose={handleCloseModal}
        onConfirm={() => handleConfirm(genItemLink(classifiedCardData))}
      />
      <Link
        href={genItemLink(classifiedCardData)}
        passHref
        target={'_blank'}
        onClick={handleClick}
      >
        <div
          className={` relative rounded-md group h-full border-[1px] border-gray-300 lg:card-hover  flex `}
          style={
            standardCardHeight && standardCardWidth
              ? {
                  width: `${standardCardWidth}px`,
                  height: `${standardCardHeight}px`,
                }
              : {}
          }
        >
          <div
            className={`flex flex-col w-full h-full rounded-md overflow-hidden bg-neutral-100 pb-2  transition-all  border-neutral-100 ${className}`}
            ref={containerRef}
          >
            {/* Avatar */}
            <div className="absolute top-0 left-0  z-10 w-full pb-16 overflow-hidden">
              <div className="absolute rounded-tr-lg rounded-r-lg inset-0 bg-gradient-to-b from-black/40 to-black/0 z-[-1] rounded-lg"></div>
              {isShop ? (
                <div className=" relative top-0 right-0 flex justify-end items-center rounded-md pr-2 pt-[6px] gap-2">
                  <div
                    className={`transform-gpu absolute skew-y-[4deg] rounded-md scale-x-[10rem] rounded-tr-[1000px] top-0 bg-gradient-to-r ${
                      isNeuf
                        ? 'from-neuf-200 via-neuf-200/30 '
                        : 'from-primary via-primary/30 '
                    } to-transparent rounded-bl-full right-0 h-[3.2rem] w-[9rem] -z-[1]`}
                  />
                  <div className="transform-gpu absolute skew-y-[4deg] scale-x-[10rem]  top-0 rounded-bl-full rounded-tr-[1000px] right-0 h-[3rem] w-[8.85rem] -z-[1] bg-neutral-100" />
                  <span className="   flex flex-col my-auto  text-sm leading-tight capitalize text-gray-800 text-right ">
                    <span className="w-[70px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {name?.toLocaleLowerCase()}
                    </span>
                    <span
                      className={`text-2xs leading-tight ${
                        isNeuf ? 'text-neuf-200' : 'text-primary'
                      } font-bold`}
                    >
                      {isNeuf ? 'Promoteur' : 'Boutique'}
                    </span>
                  </span>
                  <div className="avatar relative">
                    <div className="mask mask-squircle absolute left-[-1.5px] top-[-1.5px] w-[37px] bg-white"></div>
                    <div className={`mask mask-squircle w-[34px]`}>
                      {avatar ? (
                        <img
                          loading="lazy"
                          className="w-full"
                          src={
                            avatar.includes('http')
                              ? `https://www.tayara.tn/mediaGateway/resize-image?img=${avatar.substring(
                                  avatar.lastIndexOf('/') - 2
                                )}&w=100`
                              : `https://www.tayara.tn/mediaGateway/resize-image?img=${avatar}&w=100`
                          }
                          alt={`${name} - tayara publisher profile picture`}
                        />
                      ) : (
                        <div className="bg-neutral-500 text-blue-100 w-full h-full flex">
                          <MdOutlineStorefront className="m-auto" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="relative w-full h-full grow -mb-10 p-1 overflow-hidden">
              <div className={`w-full aspect-video`}></div>
              <Image
                src={
                  imgSrc.includes('http')
                    ? `https://www.tayara.tn/mediaGateway/resize-image?img=${imgSrc.substring(
                        imgSrc.lastIndexOf('/') - 2
                      )}&w=300`
                    : `https://www.tayara.tn/mediaGateway/resize-image?img=${imgSrc}&w=300`
                }
                // To do check why width is set to 640 when using loader
                unoptimized
                loading="lazy"
                {...(classifiedCardData.imgLoad
                  ? {
                      placeholder: 'blur',
                      blurDataURL: `data:image/webp;base64,${classifiedCardData.imgLoad}`,
                    }
                  : {})}
                className={`bg-neutral-300 w-full !h-[95%] object-cover`}
                fill
                // width={210}
                // height={350}
                // TODO Make this an actual alternative title
                alt={`publié par ${name} - ${title} - ${description} - ${subCategory}`}
              />

              {/* Angled block (incliné) */}
              <div
                className={`absolute skew-y-[4deg] scale-x-105 -bottom-4 h-16 left-0 right-0 w-full z-[2] bg-neutral-100`}
              >
                {isShop && (
                  <div
                    className={`relative top-[-2px] h-[4px] w-full z-[5] bg-gradient-to-r from-transparent ${
                      isNeuf
                        ? 'via-neuf-100/50 to-neuf-100'
                        : 'via-primary/50 to-primary'
                    } `}
                  />
                )}
              </div>
            </div>

            <div
              className={`p-0 px-3 z-10 flex-none ${price > 1 && 'mt-[1px]'}`}
            >
              {price > 1 ? (
                <Price
                  price={price}
                  type={isNeuf ? PriceType.Neuf : PriceType.Normal}
                  className={`font-extrabold ${
                    isNeuf ? 'text-neuf-200' : 'text-primary'
                  } `}
                />
              ) : null}
              <h2
                className={`card-title font-arabic text-sm font-medium leading-5 text-gray-800 max-w-min min-w-full line-clamp-2 mb-2 mt-1`}
                dir="auto"
              >
                {classifiedCardData?.title}
              </h2>
              {/* <p className="text-2xs text-neutral-400 line-clamp-1 mt-1 font-light">
                  {classifiedCardData.description ||
                    'Fugiat ea occaecat tempor sunt nisi adipisicing ea velit cupidatat irure. Elit nisi culpa magna commodo ut dolore cillum duis qui. Magna laborum reprehenderit labore nulla sunt minim dolore sunt. Velit nulla ut ex aliqua dolor qui laboris occaecat magna culpa aliqua nostrud.'}
                </p> */}
              {/* Horizontal divider */}
              <div className="mt-1 mb-1 h-[1px] bg-black/5"></div>

              {/* Metadata */}

              <div
                className={`flex items-center space-x-1 ${metadataClassName}`}
              >
                {subCategory ? (
                  <FaShapes size={12} className="text-neutral-300" />
                ) : null}
                <Text
                  className="truncate text-xs w-3/5 font-medium text-neutral-500"
                  text={subCategory}
                />
              </div>
              <div
                className={`flex items-center space-x-1 ${metadataClassName}`}
              >
                <HiLocationMarker size={12} className="text-neutral-300" />
                <Text
                  className="truncate text-xs w-3/5 font-medium text-neutral-500"
                  text={`${classifiedCardData.location?.governorate}, ${dayjs(
                    classifiedCardData.metadata?.publishedOn
                  ).fromNow()}`}
                />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </article>
  );
};

export default ClassifiedCard;
